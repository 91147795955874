
.fotoFundoLogin {
    width: 100%;
    background: url('./../../assets/imagens/Background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

.form-item-code {
    width: 52px;
    height: 62px;
    text-align: center;
    font-size: 25px;
    border-bottom: 1.5px solid #d2d2d2;
    box-shadow: 1px 1px 1px 1px #d2d2d2;
    margin: 0 10px;
  }

  .form-item-code:focus {
    border-bottom: 1.5px solid #3663b2;
    box-shadow: 1px 1px 1px 1px #3663b2;
    outline: none;
  }
